
    import { Component, Prop, Vue } from "vue-property-decorator";
    import { LoginAutoLoginBody, LoginSignInModel, LoginTypeEnum } from "@/apiManager/login/loginData";
    import AccessToken from "@/models/accessToken";
    import AuthenticationModalPayload from "@/store/modules/authenticationModal/models";
    import JwtDecode from "jwt-decode";
    import RoutesEnum from "@/router/routesEnum";

    @Component
    export default class AutoLogin extends Vue
    {
        @Prop({ type: String, default: "" }) private readonly token!: string;

        errorServerMessageEn: string = "";
        errorServerMessageFr: string = "";
        hasErrorServer: boolean = false;
        signInProgress: boolean = true;

        async autoLoginAsync()
        {
            this.signInProgress = true;

            try
            {
                let tokenDecoded: any = JwtDecode(this.token);

                if (this.token.length === 0)
                {
                    throw "";
                }

                if ((tokenDecoded.agent === undefined) || (tokenDecoded.agent.length === 0))
                {
                    throw "";
                }

                let tokenModel: LoginSignInModel = await Vue.$apiManager.login.autoLoginAsync(new LoginAutoLoginBody(this.token));

                AccessToken.Set(tokenModel.token);
                let loginType: LoginTypeEnum = AccessToken.GetType();

                this.$tStore.commit(new AuthenticationModalPayload.Mutations.SetIsAuth(true, loginType));
                this.$router.replace({ name: RoutesEnum.Package });
            }
            catch (error: any)
            {
                let statusCode: number = error?.response?.status ?? 0;
                if (statusCode >= 500)
                {
                    this.errorServerMessageFr = "Une erreur de communication avec le service est survenue. Veuillez réessayer ultérieurement";
                    this.errorServerMessageEn = "A communication error with the service has occurred. Please retry later";
                }
                else
                {
                    this.errorServerMessageFr = "Token d'authentification invalide";
                    this.errorServerMessageEn = "Invalid authentication token";
                }

                this.hasErrorServer = true;
            }
            finally
            {
                this.signInProgress = false;
            }
        }

        mounted()
        {
            this.autoLoginAsync();
        }
    }
